import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="Support">
      <div className="headline">
        <h1 className="text-primary">Getting Started</h1>
      </div>
      <p>
        Liquid Open API Platform provides a collection of APIs to help you build
        scalable and simple integration for a wide range of use cases on mobile
        applications.
      </p>
      <p>
        We are excited to see what kind of customer payment journey you can
        create with Liquid APIs.
      </p>
      <p>Let’s get started!</p>

      <h2 className="h3 mt-5">
        <small style={{ color: "#267df4" }}>Step 1: </small>
        <span>Request for account</span>
      </h2>
      <p>
        Please contact Liquid team at{" "}
        <a href="mailto:techsupport@liquidpay.com">techsupport@liquidpay.com</a>{" "}
        to request an integration account for developing your application with
        our APIs.
      </p>

      <h2 className="h3 mt-5">
        <small style={{ color: "#267df4" }}>Step 2: </small>
        <span>Receive account details</span>
      </h2>
      <p>
        Upon confirmed by the Liquid team, Liquid will send you an email with
        necessary information to access the sandbox environment
      </p>

      <h2 className="h3 mt-5">
        <small style={{ color: "#267df4" }}>Step 3: </small>
        <span>Build your first application</span>
      </h2>
      <p>
        The sandbox provides you with the environment to test your application
        with Liquid APIs. You are all set to go! Authenticate your request when
        using the API by including your API key in the HTTP request under header
        name (Liquid-Api-Key)
      </p>

      <hr className="mt-5" />

      <h2 className="h3 mt-5">Learn more about our APIs</h2>
      <p>Access Liquid Open API Platform Documentation to get up and running quickly.</p>

      <h2 className="h3 mt-5">Encountered any problem?</h2>
      <p>Get in touch with the Liquid team at <a href="mailto:techsupport@liquidpay.com">techsupport@liquidpay.com</a>, we are here to help you!</p>
    </DocumentLayout>
  )
}
